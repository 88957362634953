import React, { useEffect } from "react";
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import { motion, useAnimation } from "framer-motion";
// import Wave from '../wave/Wave'
import { useInView } from "react-intersection-observer"
import styles from "../../css/portfolios.module.css"

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
 initial: { scale: 0.5, },
    enter: { scale: 1, transition: { duration: 1.5, ...transition }},
    exit: {
        
        scale: 0.5,
        transition: { duration: 1.5, ...transition },
    }
};

const frameVariants = {
    hover: { opacity: 0.6 },
    transition: { duration: 1.5, ...transition },
};

const imageVariants = {
    hover: { scale: 1.1 }
};

const updownVariants = {
    
    enter: {
        clipPath: "inset(0px 0px 0%)",
        transition: {
            duration: 0.2,
            type: "spring",
            stiffness: 350,
            damping: 30

        },
    },
    exit: {
        clipPath: "inset(0px 0px 100%)",
        opacity: 0,
        transition: {
            duration: 0.2,
            type: "spring",
            stiffness: 350,
            damping: 30
        },
    }
}

const updownDelayVariants = {
    ...updownVariants,
  
}


const Portfolio = ({ portfolio }) => {

    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const { title, uri, slug, content, excerpt, id, featuredImage, acfPage, acfPage: {image} } = portfolio
    const imageData = acfPage?.image?.imageFile?.childImageSharp?.fluid || "Placeholder"
    //console.log(acfPage && acfPage.image)
    // console.log(acfPage?.image || "Placeholder");
     console.log(imageData.srcSet)
    return (
        <motion.div 
            className={styles.thumbnail}  
        >
            <motion.figure
                className={styles.frame}
                whileHover={"hover"}
                variants={frameVariants}
            >
                <Link to={`/${slug}`}>
                    {/* <motion.div className={styles.bg} >
                        <Wave url={imageData.src}/>
                    </motion.div> */}
                    {/* <motion.img src={imageData.src}/> */}
                    <Img fluid={imageData} />
                </Link>
                
            </motion.figure>
            <div dangerouslySetInnerHTML={{ __html: excerpt }} />
        </motion.div>
        
    )
}

const Portfolios = ({overview}) => {
    const response = useStaticQuery(getPortfolios)
    const portfolios = response.featuredPortfolios.pages.edges
    const sortedPortfolios = portfolios.sort((a, b) => (a.node.title > b.node.title) ? 1 : -1)
    // console.log(portfolios.sort((a, b) => (a.node.title > b.node.title) ? 1 : -1))
    
    return (
        <>
            <h3 className={styles.overview}>{overview}</h3>
            <div className={styles.portfolios}>
                {sortedPortfolios.map(({ node }) => {
                    return <Portfolio key={node.id} portfolio={node} />
                })}
            </div>
        </>
    )
}

export default Portfolios

const getPortfolios = graphql`
  query getPagesAndGetPages {
        featuredPortfolios: wpgraphql {
            pages (where: {categoryId: 4}) { 
                edges {
                    node {
                        title
                        uri
                        slug
                        content
                        id
                        excerpt
                        acfPage {
                            image {
                                sourceUrl
                                altText
                                imageFile {
                                    childImageSharp {
                                        fluid(maxWidth: 600,quality: 50, cropFocus: CENTER) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                        featuredImage {
                            sourceUrl
                            altText
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 400,quality: 50, cropFocus: CENTER) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`