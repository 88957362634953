import 'intersection-observer'
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import Portfolios from "../components/portfolio/Portfolios"
import AnimatedTitles from "../components/util/AnimatedTitles"
import ReactRotatingText from "react-rotating-text"
import styles from "../css/home.module.css"
import SEO from "../components/seo"

const transition = { duration: 0.1, ease: "easeOut"}
const transitionColor = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }
const transitionTitle = { duration: 0.3, delay: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }

const textVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 0.5, ease: "easeOut", delay: 1.9 } },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: "easeOut" }
  }
}

const bgcolorVariants = {
  initial: { backgroundColor: "#fff" },
  enter: { backgroundColor: "#000", transition: { duration: 1, ...transitionColor } },
  exit: {
    backgroundColor: "#fff",
    transition: { duration: 0.2, ...transitionColor }
  },
}

const colorVariants = {
  initial: { color: "#000" },
  enter: { color: "#f9cdcd", transition: { duration: 1, ...transitionColor } },
  exit: {
    color: "#000",
    transition: { duration: 0.2, ...transitionColor }
  },
}

const bggVariants = {
  initial: { backgroundColor: "#fff", clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 0% 0%)" },
  enter: { backgroundColor: ["#fff", "#000", "#000"], clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)", transition: { duration: 1.3, ease: "easeOut", delay: 0.8, times: [0, 0.1, 1.3] } },
  exit: {
    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
    transition: { duration: 0.2, ease: "easeOut" }
  }
}

const IndexPage = () => {

  const [contentRef, inView] = useInView({ triggerOnce: false, threshold:0.8, })

  const data = useStaticQuery(getImage);
  const profilePic = data.profilePic.childImageSharp.fluid;
  const downtownPic = data.downtownPic.childImageSharp.fluid;
  const homeContent = data.wpgraphql.pages.nodes;
  const [{ homePage: { animatedTitleUp, animatedTitleDown, intro} }] = homeContent;

return(
  
  <motion.div
    className ={styles.container}
    initial="initial"
    animate="enter"
    exit="exit" 
    variants={{
      exit: { transition: {  } },
      enter: {  }
    }}
  >
    <SEO title="Home | Steven Yu Front end developer" />

    <motion.section className={styles.hero} variants={bggVariants}>
      {/* <motion.h1 className={styles.title} variants={textVariants}>
        thank you
      </motion.h1> */}

      <motion.p className={styles.paragraph} variants={textVariants}>Hi, I'm Steven. I'm a front end developer that focuses on  <br></br>
        <ReactRotatingText className={styles.rotate} pause="2500" items={[' modern javascript.', ' peformant UI.', ' usability.']} />
      </motion.p>
      <BackgroundImage
          Tag="section"
          className={styles.profilePicture}
          fluid={profilePic}
        >
          {/* <AnimatedTitles
            animated_title_up={animatedTitleUp}
            animated_title_down={animatedTitleDown}
          />
          <motion.p
            className={styles.intro}
            variants={textVariants}
          >
            {intro}
          </motion.p> */}
      </BackgroundImage>
    </motion.section>

    <motion.div variants={textVariants} >
      <motion.section
        animate={inView ? 'enter' : 'exit'}
        className={styles.portfolios}
        ref={contentRef}
        variants={bgcolorVariants}
        >
        <motion.div
          animate={inView ? 'enter' : 'exit'}
          className={styles.thumbnails}
          ref={contentRef}
          variants={colorVariants}
        >
          <Portfolios overview={" View select projects I've worked on ⟶"} />
        </motion.div>
      </motion.section>
   </motion.div>

  {/* <motion.section >
    <BackgroundImage
      Tag="section"
        className={styles.contact}
      fluid={downtownPic}
    >
        <motion.p  variants={textVariants}>Drop me a message if you would like to work together! 
        
        </motion.p>
    </BackgroundImage>
  </motion.section>
     */}
  <motion.section
    className ={styles.learn}
    variants={textVariants} 
  >
  <motion.h3>
    What I'm learning or have learned recently
  </motion.h3>

  <motion.p >

      I find self-development and the acquisition of new skills very important. As a result of the covid-19 situation, I have the opportunity to learn more skills to expand my knowledge of web development, as well as to acquire new skills for future-proofing.
  </motion.p>

    <motion.ul>
      <li>Udacity Deep Learning </li>
      <li>Udacity Full Stack developer </li>
      <li>Newline Full Stack React Mastercalss</li>
    </motion.ul>
    
  </motion.section>
</motion.div>
  );
};

export default IndexPage

const getImage = graphql`
  query {
    profilePic:file(relativePath: { eq: "stevenhike.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 70, cropFocus: CENTER)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
    downtownPic:file(relativePath: { eq: "downtown.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 70, cropFocus: CENTER)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wpgraphql {
      pages (where: {id: 74}) {
        nodes {
          homePage {
            animatedTitleDown
            animatedTitleUp
            intro
          }
        }
      }
    }
  }
`
